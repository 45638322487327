"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.default = sortFields;

var _has = _interopRequireDefault(require("lodash/has"));

var _toposort = _interopRequireDefault(require("toposort"));

var _propertyExpr = require("property-expr");

var _Reference = _interopRequireDefault(require("../Reference"));

var _isSchema = _interopRequireDefault(require("./isSchema"));

function sortFields(fields, excludes) {
  if (excludes === void 0) {
    excludes = [];
  }

  var edges = [],
      nodes = [];

  function addNode(depPath, key) {
    var node = (0, _propertyExpr.split)(depPath)[0];
    if (!~nodes.indexOf(node)) nodes.push(node);
    if (!~excludes.indexOf(key + "-" + node)) edges.push([key, node]);
  }

  for (var key in fields) {
    if ((0, _has.default)(fields, key)) {
      var value = fields[key];
      if (!~nodes.indexOf(key)) nodes.push(key);
      if (_Reference.default.isRef(value) && value.isSibling) addNode(value.path, key);else if ((0, _isSchema.default)(value) && value._deps) value._deps.forEach(function (path) {
        return addNode(path, key);
      });
    }
  }

  return _toposort.default.array(nodes, edges).reverse();
}

module.exports = exports.default;