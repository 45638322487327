"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.default = void 0;

var _mixed = _interopRequireDefault(require("./mixed"));

var _inherits = _interopRequireDefault(require("./util/inherits"));

var _isodate = _interopRequireDefault(require("./util/isodate"));

var _locale = require("./locale");

var _isAbsent = _interopRequireDefault(require("./util/isAbsent"));

var _Reference = _interopRequireDefault(require("./Reference"));

var invalidDate = new Date('');

var isDate = function isDate(obj) {
  return Object.prototype.toString.call(obj) === '[object Date]';
};

var _default = DateSchema;
exports.default = _default;

function DateSchema() {
  var _this = this;

  if (!(this instanceof DateSchema)) return new DateSchema();

  _mixed.default.call(this, {
    type: 'date'
  });

  this.withMutation(function () {
    _this.transform(function (value) {
      if (this.isType(value)) return value;
      value = (0, _isodate.default)(value); // 0 is a valid timestamp equivalent to 1970-01-01T00:00:00Z(unix epoch) or before.

      return !isNaN(value) ? new Date(value) : invalidDate;
    });
  });
}

(0, _inherits.default)(DateSchema, _mixed.default, {
  _typeCheck: function _typeCheck(v) {
    return isDate(v) && !isNaN(v.getTime());
  },
  min: function min(_min, message) {
    if (message === void 0) {
      message = _locale.date.min;
    }

    var limit = _min;

    if (!_Reference.default.isRef(limit)) {
      limit = this.cast(_min);
      if (!this._typeCheck(limit)) throw new TypeError('`min` must be a Date or a value that can be `cast()` to a Date');
    }

    return this.test({
      message: message,
      name: 'min',
      exclusive: true,
      params: {
        min: _min
      },
      test: function test(value) {
        return (0, _isAbsent.default)(value) || value >= this.resolve(limit);
      }
    });
  },
  max: function max(_max, message) {
    if (message === void 0) {
      message = _locale.date.max;
    }

    var limit = _max;

    if (!_Reference.default.isRef(limit)) {
      limit = this.cast(_max);
      if (!this._typeCheck(limit)) throw new TypeError('`max` must be a Date or a value that can be `cast()` to a Date');
    }

    return this.test({
      message: message,
      name: 'max',
      exclusive: true,
      params: {
        max: _max
      },
      test: function test(value) {
        return (0, _isAbsent.default)(value) || value <= this.resolve(limit);
      }
    });
  }
});
module.exports = exports.default;