'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var createIsPristine = function createIsPristine(_ref) {
  var deepEqual = _ref.deepEqual,
      empty = _ref.empty,
      getIn = _ref.getIn;
  return function (form, getFormState) {
    return function (state) {
      for (var _len = arguments.length, fields = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        fields[_key - 1] = arguments[_key];
      }

      var nonNullGetFormState = getFormState || function (state) {
        return getIn(state, 'form');
      };
      var formState = nonNullGetFormState(state);
      if (fields && fields.length) {
        return fields.every(function (field) {
          var fieldInitial = getIn(formState, form + '.initial.' + field);
          var fieldValue = getIn(formState, form + '.values.' + field);
          return deepEqual(fieldInitial, fieldValue);
        });
      }
      var initial = getIn(formState, form + '.initial') || empty;
      var values = getIn(formState, form + '.values') || initial;
      return deepEqual(initial, values);
    };
  };
};

exports.default = createIsPristine;