"use strict";

exports.__esModule = true;
exports.default = void 0;

var _default = function _default(obj) {
  return obj && obj.__isYupSchema__;
};

exports.default = _default;
module.exports = exports.default;