'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var createGetFormValues = function createGetFormValues(_ref) {
  var getIn = _ref.getIn;
  return function (form, getFormState) {
    return function (state) {
      var nonNullGetFormState = getFormState || function (state) {
        return getIn(state, 'form');
      };
      return getIn(nonNullGetFormState(state), form + '.values');
    };
  };
};

exports.default = createGetFormValues;