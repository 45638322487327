"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.default = void 0;

var _inherits = _interopRequireDefault(require("./util/inherits"));

var _mixed = _interopRequireDefault(require("./mixed"));

var _default = BooleanSchema;
exports.default = _default;

function BooleanSchema() {
  var _this = this;

  if (!(this instanceof BooleanSchema)) return new BooleanSchema();

  _mixed.default.call(this, {
    type: 'boolean'
  });

  this.withMutation(function () {
    _this.transform(function (value) {
      if (!this.isType(value)) {
        if (/^(true|1)$/i.test(value)) return true;
        if (/^(false|0)$/i.test(value)) return false;
      }

      return value;
    });
  });
}

(0, _inherits.default)(BooleanSchema, _mixed.default, {
  _typeCheck: function _typeCheck(v) {
    if (v instanceof Boolean) v = v.valueOf();
    return typeof v === 'boolean';
  }
});
module.exports = exports.default;