import moment from 'moment';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import classNames from 'classnames';

import {
  editOkr,
  cancelEditOkr,
  addLinkedOkr,
  cancelAddLinkedOkr,
  expandOkr,
  collapseOkr
} from './actions';
import CreateUpdateOkrForm from './CreateUpdateOkrForm';
import { OkrStatus } from './OkrStatus';
import { OKR_TYPE, TITLES_CONSTANT_MAP, getAddLinkedOkrFormName, getEditOkrFormName } from './constants';

const getEmployeeBubbleManagedByFilter = managerId => (
  {'employee_bubble_users_managed_by': managerId});

const getNoManagedBubblesMessage = managerName => {
  if (managerName) {
    return `No employee bubble users managed by ${managerName}`;
  }
};

export class OkrTile extends React.PureComponent {
  constructor(props) {
    super(props);
    this.toggleExpanded = this.toggleExpanded.bind(this);
  }

  toggleExpanded() {
    if (this.props.isExpanded) {
      this.props.collapseOkr(this.props.data.id);
    } else {
      this.props.expandOkr(this.props.data.id);
    }
  }

  render() {
    const {
      data,
      isDisabled,
      children,
      isMiddleLayer,
      isLastLayer,
      lockedOkrType,
      managerId,
      managerName,
      isFilteredList,
      parentOkrName,
      statusCalculationHint,
      noAutoUpdateParentStatus
    } = this.props;
    const isCompanyGoal = data.type === OKR_TYPE.COMPANY;
    const isEmployeeOkr = data.type === OKR_TYPE.EMPLOYEE;
    return (
      <div className={classNames({'okr-container-large': !isLastLayer })}>
        {this.props.editing ? (
          <CreateUpdateOkrForm
            lockedOkrType={lockedOkrType}
            cancel={this.props.cancelEditOkr}
            initialValues={{
              type: data.type,
              name: data.name,
              due_date: moment(data.due_date),
              parent_okr: data.parent_okr,
              status: isCompanyGoal ? undefined : data.status,
              owner: data.owner_id,
              comment: data.comment,
              extended_date: data.extended_date && moment(data.extended_date),
            }}
            okrId={data.id}
            form={getEditOkrFormName(data.id)}
            ownerFilter={!!managerId && getEmployeeBubbleManagedByFilter(managerId)}
            ownerNoResultsText={getNoManagedBubblesMessage(managerName)}
            largeContainer={!isLastLayer}
            hideLinkedTo={isFilteredList}
            parentOkrName={parentOkrName}
            noAutoUpdateParentStatus={noAutoUpdateParentStatus}
            noAutoUpdateStatus={!isLastLayer && !data.auto_update_status}
          />
        ) : (
          <div className="okr-tile-container">
            {!isFilteredList && !isLastLayer && (
              <div className="collapse-btn text-align-center">
                {!isEmployeeOkr && (
                  <button
                    disabled={!data.child_okrs || !data.child_okrs.length}
                    onClick={this.toggleExpanded}
                    aria-hidden
                  >
                    <i
                      className={
                        classNames(
                          'far font-size-24 color-gray-dark',
                          {
                            'fa-angle-down' : this.props.isExpanded,
                            'fa-angle-right': !this.props.isExpanded
                          }
                        )
                      }
                    >
                    </i>
                  </button>
                )}
              </div>
            )}
            <div className="okr-type-display">
              <i className={
                classNames(
                  'far font-size-18 margin-right-base color-gray-dark',
                  {
                    'fa-globe': isCompanyGoal,
                    'fa-building': data.type === OKR_TYPE.MANAGER,
                    'fa-user': isEmployeeOkr
                  }
                )
              }></i>
              <p className="margin-bottom-none text-size-13">{TITLES_CONSTANT_MAP[data.type]}</p>
            </div>
            {data.team && (
              <p className="fwb margin-bottom-none text-size-13">{data.team}</p>
            )}
            {isCompanyGoal ? (
              <p
                className="fwb margin-bottom-none text-size-13 okr-name"
                title={data.name}
              >
                {data.name}
              </p>
            ) : (
              <p
                className="fwb margin-bottom-none text-size-13 okr-name"
                title={data.name}
              >
                <a href={`/okrs/${data.id}/`}>
                  {data.name}
                </a>
              </p>
            )}
            <OkrStatus
              status={data.status}
              completedPercentage={data.percentage_complete}
              statusCalculationHint={statusCalculationHint}
            />
            <div
              className={classNames('okr-comment-div cursor-pointer', {
                'show-on-hover': !!data.comment
              })}
              data-after-content={data.comment}
            >
              <i className="far font-size-18 color-gray-dark fa-comment-dots"></i>
            </div>
            <div className="max-width-200">
              {
                data.extended_date ?
                  <p className="fwb margin-bottom-none text-size-13 color-bright-red fwb">
                    Extended to: {data.extended_date}
                  </p> :
                  <p className="fwb margin-bottom-none text-size-13">Due: {data.due_date}</p>
              }
              {data.owner_name && (
                <p className="margin-bottom-none text-size-13 text-overflow-ellipsis">Owner: {data.owner_name}</p>
              )}
            </div>
            <div className="display-flex margin-left-auto">
              {!this.props.canNotEdit && (
                <button
                  className="btn-icon"
                  disabled={isDisabled}
                  onClick={this.props.editOkr}
                >
                  <i className="far font-size-18 fa-pencil"></i>
                </button>
              )}
              {!isFilteredList && !isEmployeeOkr && (
                <button
                  className="btn-icon orange margin-left-x-large"
                  disabled={isDisabled || this.props.addingLinkedOkr}
                  onClick={this.props.addLinkedOkr}
                >
                  <i className="far font-size-32 fa-plus"></i>
                </button>
              )}
            </div>
          </div>
        )}
        <div className={classNames('okr-children-container', {'okr-container-small': isMiddleLayer})}>
          {this.props.addingLinkedOkr && (
            <CreateUpdateOkrForm
              lockedOkrType={isMiddleLayer && OKR_TYPE.EMPLOYEE}
              cancel={this.props.cancelAddLinkedOkr}
              form={getAddLinkedOkrFormName(data.id)}
              parentOkr={data.id}
              ownerFilter={isMiddleLayer && getEmployeeBubbleManagedByFilter(data.owner_id)}
              ownerNoResultsText={isMiddleLayer && getNoManagedBubblesMessage(data.owner_name)}
              ownerSelectId={`add-linked-okr-owner-select-${data.id}`}
              noAutoUpdateParentStatus={isMiddleLayer && !data.auto_update_status}
            />
          )}
          {this.props.isExpanded && children}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    addingLinkedOkr: state.addLinkedOkr.includes(props.data.id),
    editing: state.editOkr.includes(props.data.id),
    isExpanded: state.expandedOkrs.includes(props.data.id)
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    collapseOkr: () => dispatch(collapseOkr(props.data.id)),
    expandOkr: () => dispatch(expandOkr(props.data.id)),
    editOkr: () => dispatch(editOkr(props.data.id)),
    cancelEditOkr: event => {
      event.preventDefault();
      dispatch(cancelEditOkr(props.data.id));
    },
    addLinkedOkr: () => dispatch(addLinkedOkr(props.data.id)),
    cancelAddLinkedOkr: event => {
      event.preventDefault();
      dispatch(cancelAddLinkedOkr(props.data.id));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OkrTile);
